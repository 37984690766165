



























































































.pricing {
  width: 100%;
}
.text-video-container {
  color: #000010;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    margin: 0;
  }
}
.pricing-title {
  margin: 103px 0 16px;
  font-weight: bold;
  font-size: 34px;
  line-height: 36px;
}
.pricing-text {
  text-align: center;
  color: #000010;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}
.pricing-video {
  margin: 34px 0;
}
.card-container {
  margin: 0 ;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  justify-content: space-around;
  -webkit-align-items: stretch; 
  align-items: stretch; 
}
.margin-bottom-pricing {
  padding: 0 0 126px 0;
}

@media (max-width: 599px){
  .card-container {
    margin: 0;
  }
}
@media (min-width: 600px) and (max-width: 1023px) {
  .card-container {
    margin: 0;
  }
}
@media (min-width: 1024px){
  .card-container {
    margin: 0 40px 34px;
  }    
}
