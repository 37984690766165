
































































@use "@material/card";
@include card.core-styles;

.card-pricing-subtitle.button-club {
  font-size: 0;
}
.active-plan-color {
  background: linear-gradient(180deg, #00303D 0%, #016882 51.04%, #00303D 100%);
}

.card-pricing-align-end {
  
  .mdc-button {
    width: -webkit-fill-available;
    width: -moz-available;
    margin: 24px 20% 31px;
  }
}	
.mdc-card {
  height: auto;
  justify-content: space-between;
  margin-bottom: 20px; 
  @include card.fill-color(#161824);
  @include card.outline(#161824);
  @include card.shape-radius(20px)
}
.card-pricing-title {
  color: #FFFFFF;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 25px;
  margin: 34px 0 21px;
}
.card-pricing-subtitle {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin: 23px 0 17px;
}
hr {
  width: 67%;
  margin: 0 auto;
}
.card-pricing-icon {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
}

.card-pricing-text {
  color: #FFFFFF;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 7px;
}

.card-pricing-text-exclusive {
  color: #FFFFFF;
  margin: 7px 80px;
  font-size: 10px;
  line-height: 14px;
}

ul {
  margin-right: 10px;
}

li {
  display: flex;
  margin-top: 7px;
  margin-right: 30px;
}

.card-pricing-price {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #FFFFFF;
  text-align: center;
  margin-top: 95px; 
  margin-bottom: 0;
}

.card-pricing-text-small {
  color: #FFFFFF;
  margin: 0 30px;
  font-size: 10px;
  line-height: 14px;
}

@media (max-width: 599px){
  .mdc-card {
    width: 100%;
    // height: 700px;
}
}
@media (min-width: 600px) and (max-width: 1439px) {
  .mdc-card {
    width: 48%;
    // height: 800px;
  }
}
@media (min-width: 1440px){
  .mdc-card {
    width: 23%;
    // height: 800px;
  }    
}
