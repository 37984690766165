




































@use "@material/button";
.mdc-button {
  @include button.shape-radius(50% , 0, 0);
  @include button.container-fill-color(('default':rgba(255, 255, 255, 0.3), 'disabled':rgba(255, 255, 255, 0.1) ));
  @include button.ink-color(('default':#FFFFFF, 'disabled':rgba(255, 255, 255, 0.4) ));
  @include button.outline-color(#FFFFFF);
  @include button.horizontal-padding(40px);
}
.button-text {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
}
