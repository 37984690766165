



































@use "@material/tooltip/styles";

i {
  font-size: 12px;
}
